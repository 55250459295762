<template>
  <header class="header">
    <router-link to="/" class="header-logo">
      <img src="@/assets/logo.svg">
      <h3 class="heading-xxs">Onboard</h3>
    </router-link>
    <div class="header-button" v-if="isLoggedIn">
      <button class="button button-secondary button-white button-small" v-on:click="logOutUser">Log out</button>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  computed: {
    ...mapGetters(['isLoggedIn', 'user'])
  },
  methods: {
    logOutUser: function () {
      const self = this
      this.$store.dispatch('logOutUser').then(function () {
        self.$router.push('/login')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 86px;
  background: $green-80;
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  &-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 32px;
      height: 32px;
    }
    h3 {
      color: white;
      margin-left: 8px;
    }
  }
}
</style>
